main {
  div {
    .row {
      margin-top: 20px; } }

  ul {
    &:not(.browser-default) {
      &.pagination {
        padding-left: 0;

        li {
          a {
            &:hover {
              text-decoration: none; } } } } } } }

#post-title {
  margin-bottom: 2px;
  font-weight: bold; }

#post-date {
  margin-bottom: 8px;
  font-size: 14px;

  i {
    font-size: 11.5px;
    margin-right: 10px; } }

#post-content {
  margin-top: 5px; }
